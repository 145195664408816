<template>
  <div>
    <van-nav-bar title="标题" left-text="返回" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-button v-if="debt.isMerge != 1 && debt.status != 1" plain size="mini" type="primary" style="margin-right: 3px;" @click="onMerge">合并账单</van-button>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>
    <div class="van-cell van-cell--center van-cell--borderless van-contact-card van-contact-card--edit">
<!--      <i class="van-badge__wrapper van-icon van-icon-contact van-cell__left-icon"></i>-->
      <div class="van-cell__value van-cell__value--alone van-contact-card__value">
        <div>{{debt.realName}}</div><div>{{debt.totalPrice}}</div>
      </div>
      <i class="van-badge__wrapper van-icon van-icon-arrow van-cell__right-icon" v-if="debt.type == 0" @click="toInfo(debt)"></i>
    </div>
    <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }" v-if="debtChildren.length != 0"
    >
      子账单列表
    </van-divider>
    <div class="van-address-list" style="padding-bottom: 0">
      <div class="van-radio-group" role="radiogroup">
        <div v-for="item in debtChildren" :key="item.id" :name="item.id">
          <div class="van-address-item" style="margin-bottom: 5px;">
          <div class="van-cell van-cell--borderless">
            <div class="van-cell__value van-cell__value--alone van-address-item__value">
              <div role="radio" class="van-radio" tabindex="0" aria-checked="true">
                <span class="van-radio__label">
                  <div class="van-coupon__head van-address-item__name">
                    <h2 class="van-coupon__amount" style="font-size: 25px;">{{item.totalPrice}}<span>元</span></h2>
                  </div>
                  <div class="van-address-item__name">
                    <van-tag round type="warning" v-if="item.isMerge == 1">被合并</van-tag>
                    <van-tag v-if="item.type == 0" round type="primary" style="margin-left: 2px;">单一账单</van-tag>
                    <van-tag v-if="item.type == 1" round type="success" style="margin-left: 2px;">合并账单</van-tag>
                  </div>
<!--                  <div class="van-address-item__name">-->
<!--                    {{item.realName}}-->
<!--                  </div>-->
                  <div class="van-address-item__name">
                    <div class="van-coupon__valid" style="font-size: 14px; margin-left: 8px;">
                      已支付{{ item.payPrice }}元
                      <span v-if="item.isMerge != 1 && item.status != 1">还需支付
                        <span style="color: #ee0a24;font-weight: 500; font-size: 18px;">{{ item.debtPrice }}</span>元
                      </span>
                    </div>
                  </div>
                  <div class="van-address-item__address">{{item.mark}}</div>
                </span>
              </div>
            </div>
            <i class="van-badge__wrapper van-address-item__edit">
              <van-icon name="arrow" @click="toInfo(item)" />
            </i>
          </div>
        </div>
        </div>
      </div>
    </div>
    <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
    >
      支付详情
    </van-divider>
    <van-empty v-if="timelines.length == 0" description="未找到支付详情" />
    <van-steps direction="vertical" :active="timelineActive">
        <van-step v-for="item in timelines" :key="item.id" :name="item.id">
          <h3>{{item.payTime}}</h3>
          <div>支付金额：{{item.payPrice}}</div>
          <div>支付方式：{{item.payType}}</div>
          <div>备注：{{item.mark}}</div>
          <van-button type="danger" size="mini" v-if="item.canDelete" @click="deleteTimeLine(item.id)">删除</van-button>
        </van-step>
    </van-steps>
  </div>
</template>

<script>
import {
  AddressList,
  Button,
  Card,
  Cell,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  Col,
  ContactCard,
  CouponCell,
  DatetimePicker,
  Dialog,
  Divider,
  Empty,
  Field,
  Form,
  Icon,
  Image as VanImage,
  Image,
  ImagePreview,
  List,
  Loading,
  NavBar,
  Notify,
  Popup,
  PullRefresh,
  Row,
  Sidebar,
  SidebarItem,
  Step,
  Stepper,
  Steps,
  Sticky,
  SubmitBar,
  Tab,
  Tabs,
  Tag,
  Toast,
  Uploader
} from 'vant';

import * as adminOrderService from "@/api/admin/order";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Form.name]: Form,
    [Button.name]: Button,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Popup.name]: Popup,
    [ContactCard.name]: ContactCard,
    [Tag.name]: Tag,
    [VanImage.name]: VanImage,
    [Card.name]: Card,
    [Stepper.name]: Stepper,
    [SubmitBar.name]: SubmitBar,
    [Divider.name]: Divider,
    [Checkbox.name]: Checkbox,
    [DatetimePicker.name]: DatetimePicker,
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [Loading.name]: Loading,
    [PullRefresh.name]: PullRefresh,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [List.name]: List,
    [CheckboxGroup.name]: CheckboxGroup,
    [Image.name]: Image,
    [Notify.name]: Notify,
    [CouponCell.name]: CouponCell,
    [AddressList.name]: AddressList,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Empty.name]: Empty,
    [NavBar.name]: NavBar
  },
  name: "DebtInfo",
  data() {
    return {
      debtChildren: [],
      debt: {},
      timelines: [],
      timelineActive: 0
    };
  },
  created() {
    if (this.$route.params && this.$route.params.debtId) {
      this.initData()
    }
  },
  methods: {
    onMerge() {
      this.$router.push({path: '/order/debt/merge/' + this.debt.userId + '/' + this.debt.id})
    },
    toHome() {
      this.$router.push({path: '/index'})
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    initData() {
      adminOrderService.getDebtInfo(this.$route.params.debtId).then(res => {
        console.log(res)
        this.debt = res
        this.debtChildren = res.children
        this.timelines = res.timelines
        this.timelineActive = this.timelines - 1
      });
    },
    toInfo(item) {
      console.log(item)
      if (item.type == 0) {
        this.$router.push({path: '/order/info/' + item.orderId})
      } else if (item.type == 1 && this.$route.params.debtId != item.id) {
        this.$router.push({path: '/order/debt/info/' + item.id})
      }
    },
    deleteTimeLine(id) {
      Dialog.confirm({
        title: '确认此操作吗？',
        message: '请仔细检查～',
      })
        .then(() => {
          adminOrderService.deletePayTimeline(id).then(res => {
            this.$toast("删除成功")
            this.initData()
          })
        })
        .catch(() => {
          // on cancel
        });
    }
  },
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}

.order-tag-margin-left {
  margin-left: 2px
}
</style>
